import "./styles/App.css";
import { useState, useEffect } from "react";
import ReactTextTransition, { presets } from "react-text-transition";

const titleTexts = ["dough", "drift"];

function App() {
	const [index, setIndex] = useState(0);
	useEffect(() => {
		document.title = "dough.land";
		const intervalId = setInterval(() => setIndex((index) => index + 1), 5000);
		return () => clearTimeout(intervalId);
	}, []);

	return (
		<div className="App w-screen h-screen flex justify-center items-center flex-col">
			<h1 className="text-4xl">
				<ReactTextTransition
					text={titleTexts[index % titleTexts.length]}
					springConfig={presets.gentle}
					style={{ width: "50%" }}
					inline
				/>
				<span className="font-bold">.land</span>
			</h1>
			<div className="flex flex-row mt-5 gap-2">
				<a
					href="https://store.dough.land"
					className="transition-all duration-200 hover:font-bold"
				>
					store
				</a>
				<span>|</span>
				<a
					href="https://discord.gg/2MupXQMSWR"
					className="transition-all duration-200 hover:font-bold"
				>
					discord
				</a>
				<span>|</span>
				<a
					href="https://github.com/dough-land"
					className="transition-all duration-200 hover:font-bold"
				>
					github
				</a>
			</div>
		</div>
	);
}

export default App;
